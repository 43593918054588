import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Popper,
  TextField,
} from '@mui/material';
import TranscriptionWord from 'generic/components/ui/TranscriptionWord';
import { Save } from '@mui/icons-material';

function formatTime(seconds, displayHours) {
  const milliseconds = seconds * 1000;

  let hoursStr = '';
  if (displayHours) {
    const heures = Math.floor(milliseconds / (1000 * 60 * 60));
    const heuresStr = heures.toString().padStart(2, '0');
    hoursStr = `${heuresStr}:`;
  }
  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
  const secondes = Math.floor((milliseconds % (1000 * 60)) / 1000);

  const minutesStr = minutes.toString().padStart(2, '0');
  const secondesStr = secondes.toString().padStart(2, '0');

  // Retourner le temps formaté
  return `${hoursStr}${minutesStr}:${secondesStr}`;
}

const TranscriptionSentence = ({
  sentence,
  index,
  patchDocumentInComplete,
  handleTranscriptClick,
  toggleManualUserEdition,
  currentMediaTime,
  displayHours,
}) => {
  const theme = useTheme();

  const previousAnchorElPosition = useRef(undefined);
  const selectionStartTime = useRef(undefined);
  const selectionEndTime = useRef(undefined);
  const [selectedText, setSelectedText] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef();

  const handleClose = useCallback(() => {
    if (!_.isEmpty(selectedText) && !_.isEmpty(anchorEl)) {
      setSelectedText(null);
      setAnchorEl(null);
      toggleManualUserEdition(false);
    }
  }, [anchorEl, selectedText, toggleManualUserEdition]);

  const handleEditTranscription = (event, fromInput) => {
    if (!fromInput || event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      const { value } = inputRef.current;
      if (value) {
        patchDocumentInComplete({
          transcription_detection: 'Texte_json',
          transcription_sentence_start: parseFloat(sentence.start),
          transcription_word_start: selectionStartTime.current,
          transcription_word_end: selectionEndTime.current,
          transcription_replace_with: value,
        });
        handleClose();
      }
    }
    if (fromInput && event.key === 'Escape') {
      handleClose();
    }
  };

  useEffect(() => {
    if (anchorEl) {
      if (typeof anchorEl === 'object') {
        previousAnchorElPosition.current = anchorEl.getBoundingClientRect();
      } else {
        previousAnchorElPosition.current = anchorEl().getBoundingClientRect();
      }
    }
  }, [anchorEl]);

  useEffect(() => (
    () => handleClose()
  ), [handleClose]);

  const handleSelectMenu = () => {
    const selection = document.getSelection();

    if (!selection || selection.anchorOffset === selection.focusOffset) {
      handleClose();
      return;
    }

    const getBoundingClientRect = () => {
      if (selection.rangeCount === 0 && previousAnchorElPosition.current) {
        setSelectedText(null);
        return previousAnchorElPosition.current;
      }
      return selection.getRangeAt(0).getBoundingClientRect();
    };
    const startTime = selection.baseNode.parentElement.getAttribute('start');
    let endTime = selection.extentNode.parentElement.getAttribute('start');
    if (!endTime) {
      if (sentence.sentence.startsWith(selection.text)) {
        endTime = sentence.words[0].start;
      } else {
        // NE FONCTIONNE PAS : Si start était dans la phrase précédente (le div au dessus),
        // on ne pourra pas retrouver le texte
        endTime = sentence.words[sentence.words.length - 1].start;
      }
    }
    if (startTime && endTime) {
      const chronologicalStart = Math.min(parseFloat(startTime), parseFloat(endTime));
      const chronologicalEnd = Math.max(parseFloat(startTime), parseFloat(endTime));
      if (chronologicalStart >= sentence.start
        && chronologicalEnd <= parseFloat(sentence.words[sentence.words.length - 1].start)) {
        let textToEdit = '';
        sentence.words.forEach(
          (word) => {
            if (parseFloat(word.start) >= chronologicalStart
              && parseFloat(word.start) <= chronologicalEnd) {
              textToEdit += `${word.word.trim()} `;
            }
          },
        );
        if (textToEdit) {
          setSelectedText(textToEdit.trim());
          selectionStartTime.current = chronologicalStart;
          selectionEndTime.current = chronologicalEnd;
          setAnchorEl({ getBoundingClientRect });
          toggleManualUserEdition(true);
          setTimeout(() => {
            inputRef.current.focus();
          }, 0);
        }
      }
    }
  };

  return (
    <Fragment>
      <Box
        key={index}
        id={index}
        mb={1.5}
        onPointerUp={!_.isEmpty(sentence.words) ? handleSelectMenu : _.noop}
      >
        <Box
          component="span"
          display="inline-flex"
          sx={{
            userSelect: 'none',
            opacity: currentMediaTime > +sentence.start ? 1 : 0.5,
          }}
        >
          <Box
            component="span"
            cursor="pointer"
            sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
            onClick={() => handleTranscriptClick(sentence.start)}
          >
            {formatTime(sentence.start, displayHours)}
          </Box>
          <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
          <Box
            component="span"
            sx={{ fontWeight: '500' }}
          >
            {`${sentence.spkid}`}
          </Box>
          &nbsp;&gt;&nbsp;
        </Box>
        {_.isEmpty(sentence.words) ? (
          <Box component="span" sx={{ '&::first-letter': { textTransform: 'uppercase' } }}>{sentence.sentence}</Box>
        ) : (
          <Box component="span">
            {_.map(
              sentence.words,
              (word) => (
                <TranscriptionWord
                  currentMediaTime={currentMediaTime}
                  handleTranscriptClick={handleTranscriptClick}
                  key={word.start}
                  preventClick={!!selectedText}
                  word={word}
                />
              ),
            )}
          </Box>
        )}
      </Box>
      <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
        <Popper
          anchorEl={anchorEl}
          keepMounted={false}
          open={!_.isEmpty(selectedText) && !_.isEmpty(anchorEl)}
          sx={{ zIndex: 1800 }}
        >
          <Box
            component="form"
            onSubmit={handleEditTranscription}
            sx={{
              alignItems: 'center',
              backgroundColor: theme.palette.mode === 'light' ? '#dddddd' : '#333333',
              borderRadius: '8px',
              display: 'flex',
              p: 1,
            }}
          >
            <TextField
              defaultValue={selectedText}
              inputRef={inputRef}
              maxRows="3"
              minRows="1"
              multiline
              onFocus={(event) => event.target.select()}
              onKeyDown={(event) => handleEditTranscription(event, true)}
              sx={{ width: '250px', mr: 1 }}
              variant="standard"
            />
            <Button type="submit">
              <Save />
            </Button>
          </Box>
        </Popper>
      </ClickAwayListener>
    </Fragment>
  );
};

TranscriptionSentence.propTypes = {
  currentMediaTime: PropTypes.number,
  displayHours: PropTypes.bool,
  handleTranscriptClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  patchDocumentInComplete: PropTypes.func.isRequired,
  sentence: PropTypes.shape({
    sentence: PropTypes.string,
    spkid: PropTypes.string,
    start: PropTypes.string,
    words: PropTypes.arrayOf(PropTypes.shape({
      confidence: PropTypes.string,
      start: PropTypes.string,
      word: PropTypes.string,
    })),
  }).isRequired,
  toggleManualUserEdition: PropTypes.func.isRequired,
};

TranscriptionSentence.defaultProps = {
  currentMediaTime: 0,
  displayHours: true,
};

export default TranscriptionSentence;
