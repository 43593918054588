import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import CollectionDialogContainer from 'generic/containers/CollectionDialogContainer';
import Collections from 'generic/components/pages/Collections';

import {
  activateCollection,
  openCollectionDialog,
  types,
} from 'generic/core/collections/actions';

import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';

const CollectionsContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [search, setSearch] = useState();
  const [inputSearch, setInputSearch] = useState('');

  const idUser = useSelector((state) => state.config.user.utilisateur);
  const collectionTypes = useSelector((state) => state.collections.collectionTypes);
  const collections = useSelector((state) => state.collections.collections);

  let filteredCollections = collections;

  if (inputSearch && !search) {
    filteredCollections = _.filter(
      filteredCollections,
      (item) => _.includes(item.libelle.toLowerCase(), inputSearch.toLowerCase()),
    );
  } else if (search) {
    filteredCollections = _.filter(filteredCollections, (item) => item.collection === search);
  }

  const handleActivateCollection = (id) => {
    dispatch(activateCollection(id));
  };

  const handleChangeAutocomplete = (value) => {
    setSearch(value?.collection);
  };

  const handleChangeInputAutocomplete = (value) => {
    setInputSearch(value);
  };

  const handleOpenCollectionDialog = (event, formValues) => {
    event.stopPropagation();
    dispatch(openCollectionDialog(formValues));
  };

  const handleOpenDeleteCollectionConfirmDialog = (event, ids) => {
    event.stopPropagation();
    dispatch(setConfirmDialogData({
      title: t('collections.delete_dialog', { count: ids.length }),
      message: (
        <div>
          {t('collections.delete', { count: ids.length })}
          <ul>
            {_.map(ids, (id) => <li key={id}>{_.find(collections, { collection: id }).libelle}</li>)}
          </ul>
        </div>
      ),
      submitColor: 'error',
      waitForActions: [types.DELETE_COLLECTIONS_SUCCESS, types.DELETE_COLLECTIONS_ERROR],
      action: {
        type: types.DELETE_COLLECTIONS,
        ids,
      },
    }));
    return false;
  };

  return (
    <Fragment>
      <Collections
        collectionTypes={collectionTypes}
        filteredCollections={filteredCollections}
        inputSearch={inputSearch}
        idUser={idUser}
        handleActivateCollection={handleActivateCollection}
        handleChangeAutocomplete={handleChangeAutocomplete}
        handleChangeInputAutocomplete={handleChangeInputAutocomplete}
        handleOpenCollectionDialog={handleOpenCollectionDialog}
        handleOpenDeleteCollectionConfirmDialog={handleOpenDeleteCollectionConfirmDialog}
      />
      <CollectionDialogContainer
        collectionTypes={collectionTypes}
        idUser={idUser}
      />
    </Fragment>
  );
};

export default CollectionsContainer;
