import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';

import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';

import TooltipButton from 'generic/components/ui/TooltipButton';
import { Delete, Edit, Image } from '@mui/icons-material';

const CollectionItem = ({
  collection,
  isOwner,
  handleActivateCollection,
  handleOpenCollectionDialog,
  handleOpenDeleteCollectionConfirmDialog,
}) => {
  const { t } = useTranslation();
  const { API_ENTRY_POINT } = QES_CONSTANTS;

  const logon = getLogonFromStorage();
  const key = getTokenFromStorage();

  let imgSrc = '';
  if (collection.image) {
    imgSrc = `${API_ENTRY_POINT}/file?type=11&file=${collection.image}&logon=${logon}&key=${key}`;
  }

  return (
    <Card
      sx={{
        minWidth: 275,
        mb: 1,
        mr: 1,
        border: collection.active ? '2px solid' : '1px solid',
        borderColor: collection.active ? 'primary.main' : 'divider',
      }}
    >
      <CardActionArea onClick={() => handleActivateCollection(collection.collection)}>
        <CardContent sx={{ display: 'flex', gap: '16px' }}>
          <Avatar
            src={imgSrc}
            alt=""
            sx={{
              width: 80,
              height: 80,
              borderRadius: '0%',
            }}
          >
            <Image sx={{ fontSize: 80 }} />
          </Avatar>
          <Box sx={{ flex: 1 }}>
            <Typography>{collection.libelle}</Typography>
            <Box sx={{ display: 'flex', mb: 0.5 }}>
              <Typography
                sx={{
                  color: 'text.secondary',
                  fontStyle: 'italic',
                }}
              >
                {t(`collections.visibility_values.${collection.visibilite_collection}`)}
              </Typography>
              { !isOwner && collection.type_collection !== 1000 && (
                <Fragment>
                  <Box mx={1} py="3px"><Divider orientation="vertical" /></Box>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontStyle: 'italic',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {`${collection.utilisateur_createur.nom} ${collection.utilisateur_createur.prenom}`}
                  </Typography>
                </Fragment>
              )}
            </Box>
            <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {collection.description}
            </Typography>
          </Box>
          <CardActions sx={{ alignItems: 'flex-end', p: 0 }}>
            <TooltipButton
              color="primary"
              onClick={(event) => handleOpenCollectionDialog(
                event,
                {
                  id: collection.collection,
                  owner: collection.utilisateur_createur,
                  visibility: collection.visibilite_collection,
                  collaborators: collection.collaborators,
                  type: collection.type_collection,
                  libelle: collection.libelle,
                  description: collection.description,
                  image: collection.image,
                },
              )}
              size="extraSmall"
              tag="fab"
              title={t('collections.edit')}
            >
              <Edit fontSize="small" />
            </TooltipButton>
            {isOwner && (
              <TooltipButton
                color="primary"
                onClick={(event) => handleOpenDeleteCollectionConfirmDialog(event, [collection.collection])}
                size="extraSmall"
                tag="fab"
                title={t('collections.delete')}
              >
                <Delete fontSize="small" />
              </TooltipButton>
            )}
          </CardActions>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CollectionItem.propTypes = {
  collection: PropTypes.shape({
    collection: PropTypes.number,
    visibilite_collection: PropTypes.number,
    collaborators: PropTypes.arrayOf(PropTypes.shape({})),
    type_collection: PropTypes.number,
    libelle: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    active: PropTypes.bool,
    utilisateur_createur: PropTypes.shape({
      utilisateur: PropTypes.number,
      nom: PropTypes.string,
      prenom: PropTypes.string,
      email: PropTypes.number,
    }),
  }).isRequired,
  isOwner: PropTypes.bool.isRequired,
  handleActivateCollection: PropTypes.func.isRequired,
  handleOpenCollectionDialog: PropTypes.func.isRequired,
  handleOpenDeleteCollectionConfirmDialog: PropTypes.func.isRequired,
};

export default CollectionItem;
