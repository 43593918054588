export const types = {
  ACTIVATE_COLLECTION: '@COLLECTIONS/ACTIVATE_COLLECTION',
  ACTIVATE_COLLECTION_SUCCESS: '@COLLECTIONS/ACTIVATE_COLLECTION_SUCCESS',
  ACTIVATE_COLLECTION_ERROR: '@COLLECTIONS/ACTIVATE_COLLECTION_ERROR',
  CLEANUP_COLLECTIONS: '@COLLECTIONS/CLEANUP_COLLECTIONS',
  CLEAR_UPLOAD_COLLECTION_IMAGE: '@COLLECTIONS/CLEAR_UPLOAD_COLLECTION_IMAGE',
  CLOSE_COLLECTION_DIALOG: '@COLLECTIONS/CLOSE_COLLECTION_DIALOG',
  CREATE_COLLECTION: '@COLLECTIONS/CREATE_COLLECTION',
  CREATE_COLLECTION_SUCCESS: '@COLLECTIONS/CREATE_COLLECTION_SUCCESS',
  CREATE_COLLECTION_ERROR: '@COLLECTIONS/CREATE_COLLECTION_ERROR',
  EDIT_COLLECTION: '@COLLECTIONS/EDIT_COLLECTION',
  EDIT_COLLECTION_SUCCESS: '@COLLECTIONS/EDIT_COLLECTION_SUCCESS',
  EDIT_COLLECTION_ERROR: '@COLLECTIONS/EDIT_COLLECTION_ERROR',
  DELETE_COLLECTIONS: '@COLLECTIONS/DELETE_COLLECTIONS',
  DELETE_COLLECTIONS_SUCCESS: '@COLLECTIONS/DELETE_COLLECTIONS_SUCCESS',
  DELETE_COLLECTIONS_ERROR: '@COLLECTIONS/DELETE_COLLECTIONS_ERROR',
  FETCH_COLLECTIONS: '@COLLECTIONS/FETCH_COLLECTIONS',
  FETCH_COLLECTIONS_SUCCESS: '@COLLECTIONS/FETCH_COLLECTIONS_SUCCESS',
  FETCH_COLLECTIONS_ERROR: '@COLLECTIONS/FETCH_COLLECTIONS_ERROR',
  FETCH_COLLECTION_TYPES: '@COLLECTIONS/FETCH_COLLECTION_TYPES',
  FETCH_COLLECTION_TYPES_SUCCESS: '@COLLECTIONS/FETCH_COLLECTION_TYPES_SUCCESS',
  FETCH_COLLECTION_TYPES_ERROR: '@COLLECTIONS/FETCH_COLLECTION_TYPES_ERROR',
  OPEN_COLLECTION_DIALOG: '@COLLECTIONS/OPEN_COLLECTION_DIALOG',
  UPLOAD_COLLECTION_IMAGE: '@COLLECTIONS/UPLOAD_COLLECTION_IMAGE',
  UPLOAD_COLLECTION_IMAGE_SUCCESS: '@COLLECTIONS/UPLOAD_COLLECTION_IMAGE_SUCCESS',
  UPLOAD_COLLECTION_IMAGE_ERROR: '@COLLECTIONS/UPLOAD_COLLECTION_IMAGE_ERROR',
};

/* Actions des collections */
export const cleanupCollections = () => ({
  type: types.CLEANUP_COLLECTIONS,
});

export const fetchCollectionTypes = () => ({
  type: types.FETCH_COLLECTION_TYPES,
});

export const fetchCollectionTypesSuccess = (collectionTypes) => ({
  type: types.FETCH_COLLECTION_TYPES_SUCCESS,
  collectionTypes,
});

export const fetchCollectionTypesError = (response) => ({
  type: types.FETCH_COLLECTION_TYPES_ERROR,
  response,
});

export const fetchCollections = () => ({
  type: types.FETCH_COLLECTIONS,
});

export const fetchCollectionsSuccess = (collections) => ({
  type: types.FETCH_COLLECTIONS_SUCCESS,
  collections,
});

export const fetchCollectionsError = (response) => ({
  type: types.FETCH_COLLECTIONS_ERROR,
  response,
});

export const activateCollection = (id) => ({
  type: types.ACTIVATE_COLLECTION,
  id,
});

export const activateCollectionSuccess = (id) => ({
  type: types.ACTIVATE_COLLECTION_SUCCESS,
  id,
});

export const activateCollectionError = (response) => ({
  type: types.ACTIVATE_COLLECTION_ERROR,
  response,
});

export const openCollectionDialog = (formValues) => ({
  type: types.OPEN_COLLECTION_DIALOG,
  formValues,
});

export const closeCollectionDialog = () => ({
  type: types.CLOSE_COLLECTION_DIALOG,
});

export const clearUploadCollectionImage = () => ({
  type: types.CLEAR_UPLOAD_COLLECTION_IMAGE,
});

export const createCollection = (formValues) => ({
  type: types.CREATE_COLLECTION,
  formValues,
});

export const createCollectionSuccess = () => ({
  type: types.CREATE_COLLECTION_SUCCESS,
});

export const createCollectionError = () => ({
  type: types.CREATE_COLLECTION_ERROR,
});

export const editCollection = (formValues) => ({
  type: types.EDIT_COLLECTION,
  formValues,
});

export const editCollectionSuccess = () => ({
  type: types.EDIT_COLLECTION_SUCCESS,
});

export const editCollectionError = () => ({
  type: types.EDIT_COLLECTION_ERROR,
});

export const deleteCollections = (ids) => ({
  type: types.DELETE_COLLECTIONS,
  ids,
});

export const deleteCollectionSuccess = () => ({
  type: types.DELETE_COLLECTIONS_SUCCESS,
});

export const deleteCollectionsError = () => ({
  type: types.DELETE_COLLECTIONS_ERROR,
});
