import { types } from 'generic/core/collections/actions';
import _ from 'lodash';

const collectionsReducer = (
  state = {
    activeCollection: {},
    collections: [],
    dialogCollection: {},
    loadingCollections: true,
    loadingCollectionTypes: true,
    collectionTypes: [],
  },
  action,
) => {
  switch (action.type) {
    /* Reducer des collections */
    case types.CLEANUP_COLLECTIONS: {
      return {
        ...state,
        collections: [],
        collectionTypes: [],
      };
    }
    case types.FETCH_COLLECTIONS: {
      return {
        ...state,
        collections: [],
        loadingCollections: true,
      };
    }
    case types.FETCH_COLLECTIONS_SUCCESS: {
      return {
        ...state,
        activeCollection: _.find(action.collections, { active: true }) ?? {},
        collections: action.collections,
        loadingCollections: false,
      };
    }
    case types.FETCH_COLLECTIONS_ERROR: {
      return {
        ...state,
        loadingCollections: false,
      };
    }
    case types.FETCH_COLLECTION_TYPES: {
      return {
        ...state,
        collectionTypes: [],
        loadingCollectionTypes: true,
      };
    }
    case types.FETCH_COLLECTION_TYPES_SUCCESS: {
      return {
        ...state,
        collectionTypes: action.collectionTypes,
        loadingCollectionTypes: false,
      };
    }
    case types.FETCH_COLLECTION_TYPES_ERROR: {
      return {
        ...state,
        loadingCollectionTypes: false,
      };
    }
    case types.ACTIVATE_COLLECTION_SUCCESS: {
      const collectionsUpdated = state.collections.map((item) => ({
        ...item,
        active: item.collection === action.id,
      }));

      return {
        ...state,
        collections: collectionsUpdated,
        activeCollection: _.find(collectionsUpdated, { active: true }) ?? {},
      };
    }
    case types.OPEN_COLLECTION_DIALOG: {
      return {
        ...state,
        dialogCollection: {
          open: true,
          id: action.formValues?.id,
          owner: action.formValues?.owner,
          visibility: action.formValues?.visibility,
          collaborators: action.formValues?.collaborators,
          type: action.formValues?.type,
          libelle: action.formValues?.libelle,
          description: action.formValues?.description,
          fileUploadName: action.formValues?.image,
        },
      };
    }
    case types.CLOSE_COLLECTION_DIALOG: {
      return {
        ...state,
        dialogCollection: {
          open: false,
        },
      };
    }
    case types.UPLOAD_COLLECTION_IMAGE: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          fileUploading: true,
        },
      };
    }
    case types.UPLOAD_COLLECTION_IMAGE_SUCCESS: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          fileUploadName: action.results.filename,
          fileUploading: false,
        },
      };
    }
    case types.UPLOAD_COLLECTION_IMAGE_ERROR: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          fileUploading: false,
        },
      };
    }
    case types.CLEAR_UPLOAD_COLLECTION_IMAGE: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          fileUploadName: '',
        },
      };
    }
    case types.CREATE_COLLECTION: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          loading: true,
        },
      };
    }
    case types.CREATE_COLLECTION_SUCCESS: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          loading: false,
        },
      };
    }
    case types.CREATE_COLLECTION_ERROR: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          loading: false,
        },
      };
    }
    case types.EDIT_COLLECTION: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          loading: true,
        },
      };
    }

    case types.EDIT_COLLECTION_SUCCESS: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          loading: false,
        },
      };
    }

    case types.EDIT_COLLECTION_ERROR: {
      return {
        ...state,
        dialogCollection: {
          ...state.dialogCollection,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default collectionsReducer;
