import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import { Add, Image } from '@mui/icons-material';
import TooltipButton from 'generic/components/ui/TooltipButton';
import CollectionItem from 'generic/components/pages/CollectionItem';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { getLogonFromStorage, getTokenFromStorage } from 'generic/utils/utils';

const Collections = ({
  collectionTypes,
  filteredCollections,
  inputSearch,
  idUser,
  handleActivateCollection,
  handleChangeAutocomplete,
  handleChangeInputAutocomplete,
  handleOpenCollectionDialog,
  handleOpenDeleteCollectionConfirmDialog,
}) => {
  const { t } = useTranslation();
  const { API_ENTRY_POINT } = QES_CONSTANTS;

  const groupByCollectionTypes = _.groupBy(filteredCollections, 'type_collection');

  const logon = getLogonFromStorage();
  const keyToken = getTokenFromStorage();

  return (
    <Box display="flex" flexDirection="column" width="100%" height="calc(100vh - 64px)" py={2}>
      <Box display="flex" alignItems="center" gap="16px" px={2} pb={4}>
        <Autocomplete
          freeSolo
          sx={{ width: '100%' }}
          options={filteredCollections}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return option.libelle;
          }}
          renderInput={(params) => (
            <TextField {...params} label={t('collections.search')} margin="normal" />
          )}
          onChange={(event, value) => handleChangeAutocomplete(value)}
          inputValue={inputSearch}
          onInputChange={(event, value) => handleChangeInputAutocomplete(value)}
          renderOption={(params, option, { inputValue }) => {
            const { key, ...optionProps } = params;
            const matches = match(option.libelle, inputValue, { insideWords: true });
            const parts = parse(option.libelle, matches);

            let imgSrc = '';
            if (option.image) {
              imgSrc = `${API_ENTRY_POINT}/file?type=11&file=${option.image}&logon=${logon}&key=${keyToken}`;
            }
            return (
              <li key={key} {...optionProps}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Avatar
                    src={imgSrc}
                    alt=""
                    sx={{
                      width: 50,
                      height: 50,
                      borderRadius: '0%',
                    }}
                  >
                    <Image sx={{ fontSize: 80 }} />
                  </Avatar>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </Box>
              </li>
            );
          }}
        />
        <TooltipButton
          tag="fab"
          title={t('collections.create')}
          color="primary"
          size="small"
          onClick={(event) => handleOpenCollectionDialog(event)}
        >
          <Add fontSize="small" />
        </TooltipButton>
      </Box>
      {_.isEmpty(groupByCollectionTypes)
        ? (<Box display="flex" justifyContent="center" px={2}>{t('collections.noResults')}</Box>)
        : (
          <Grid container spacing={2} sx={{ px: 2, overflow: 'auto' }}>
            {_.map(
              groupByCollectionTypes,
              (collectionType, index) => (
                <Grid
                  key={index}
                  item
                  sm={12}
                  md={6}
                  lg={4}
                >
                  <Card
                    sx={{
                      height: 450,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      p: 3,
                      backgroundColor: 'action.hover',
                    }}
                  >
                    <Typography variant="h6">{_.find(collectionTypes, { type_collection: +index }).libelle}</Typography>
                    <Box sx={{ overflow: 'auto' }}>
                      {_.map(
                        collectionType,
                        (collection) => (
                          <Box key={collection.collection}>
                            <CollectionItem
                              collection={collection}
                              isOwner={collection.utilisateur === idUser}
                              handleActivateCollection={handleActivateCollection}
                              handleOpenCollectionDialog={handleOpenCollectionDialog}
                              handleOpenDeleteCollectionConfirmDialog={handleOpenDeleteCollectionConfirmDialog}
                            />
                          </Box>
                        ),
                      )}
                    </Box>
                  </Card>
                </Grid>
              ),
            )}
          </Grid>
        )}
    </Box>
  );
};

Collections.propTypes = {
  collectionTypes: PropTypes.arrayOf(PropTypes.shape({
    type_collection: PropTypes.number,
    libelle: PropTypes.string,
  })).isRequired,
  filteredCollections: PropTypes.arrayOf(PropTypes.shape({
    collection: PropTypes.number,
    type_collection: PropTypes.number,
    libelle: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  })).isRequired,
  idUser: PropTypes.number.isRequired,
  inputSearch: PropTypes.string.isRequired,
  handleActivateCollection: PropTypes.func.isRequired,
  handleChangeAutocomplete: PropTypes.func.isRequired,
  handleChangeInputAutocomplete: PropTypes.func.isRequired,
  handleOpenCollectionDialog: PropTypes.func.isRequired,
  handleOpenDeleteCollectionConfirmDialog: PropTypes.func.isRequired,
};

export default Collections;
