import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CollectionDialog from 'generic/components/dialogs/CollectionDialog';
import {
  clearUploadCollectionImage,
  closeCollectionDialog,
  createCollection,
  editCollection,
  types,
} from 'generic/core/collections/actions';

const CollectionDialogContainer = ({ collectionTypes, idUser }) => {
  const {
    open,
    loading,
    id,
    owner,
    visibility,
    collaborators,
    type,
    libelle,
    description,
    fileUploadName,
    fileUploading,
  } = useSelector((state) => state.collections.dialogCollection);

  const dispatch = useDispatch();

  const initialValues = {
    visibilite_collection: id ? visibility : 1000,
    collaborators: id ? collaborators : [],
    type_collection: id ? type : collectionTypes[0].type_collection,
    libelle: id ? libelle : '',
    description: id ? description : '',
    image: id ? fileUploadName : '',
  };

  // Champ désactivé si on est en mode 'edition' et que l'utilisateur connecté n'est pas le propriétaire de la
  // collection
  const isDisabled = id && owner?.utilisateur !== idUser;

  const uploadActions = {
    loading: { type: types.UPLOAD_COLLECTION_IMAGE },
    success: { type: types.UPLOAD_COLLECTION_IMAGE_SUCCESS },
    error: { type: types.UPLOAD_COLLECTION_IMAGE_ERROR },
  };

  const handleClearUploadField = () => {
    dispatch(clearUploadCollectionImage());
  };

  const handleClose = () => {
    dispatch(closeCollectionDialog());
  };

  const handleSubmit = (formValues) => {
    if (id) {
      dispatch(editCollection({ ...formValues, id }));
    } else {
      dispatch(createCollection(formValues));
    }
  };

  if (!open) {
    return null;
  }

  return (
    <CollectionDialog
      editOrCreate={id ? 'edit' : 'create'}
      fileUploading={fileUploading}
      fileUploadName={fileUploadName}
      initialValues={initialValues}
      isDisabled={isDisabled}
      loading={loading}
      collectionTypes={collectionTypes}
      uploadActions={uploadActions}
      handleClearUploadField={handleClearUploadField}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  );
};

CollectionDialogContainer.propTypes = {
  collectionTypes: PropTypes.arrayOf(PropTypes.shape({
    type_collection: PropTypes.number,
    libelle: PropTypes.string,
  })).isRequired,
  idUser: PropTypes.number.isRequired,
};

export default CollectionDialogContainer;
