import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardMedia,
} from '@mui/material';
import { Virtuoso } from 'react-virtuoso';
import TranscriptionSentence from 'generic/components/ui/TranscriptionSentence';
import Waveform from 'generic/components/ui/WaveSurfer';
import _ from 'lodash';

const MediaWithTranscription = ({
  urlAudio,
  urlVideo,
  patchDocumentInComplete,
  transcriptionJSON,
}) => {
  const mediaRef = useRef(null);
  const virtuoso = useRef(null);
  const manualUserScroll = useRef(false);
  const manualUserEdition = useRef(false);
  const mediaWasPlayingBeforeEdition = useRef(false);
  const [currentMediaTime, setCurrentMediaTime] = useState(0);
  const parseTextJson = JSON.parse(transcriptionJSON);

  let manualScrollTimeout = null;
  const handleManualScroll = () => {
    clearTimeout(manualScrollTimeout);
    manualUserScroll.current = true;
    manualScrollTimeout = setTimeout(() => {
      manualUserScroll.current = false;
    }, 3000);
  };

  const handlePlayOrPauseMediaWithSpace = (event) => {
    if (mediaRef.current && !manualUserEdition.current && event.key === ' ') {
      if (!mediaRef.current.paused) {
        mediaRef.current.pause();
      } else {
        mediaRef.current.play();
      }
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (mediaRef.current) {
      document.getElementById('transcription').addEventListener('wheel', handleManualScroll);
      document.addEventListener('keydown', handlePlayOrPauseMediaWithSpace);
    }
    return () => {
      if (document.getElementById('transcription')) {
        document.getElementById('transcription').removeEventListener('wheel', handleManualScroll);
      }
      document.removeEventListener('keydown', handlePlayOrPauseMediaWithSpace);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaRef]);

  const handleTranscriptClick = (time) => {
    if (mediaRef.current) {
      manualUserScroll.current = false;
      manualUserEdition.current = false;
      mediaRef.current.currentTime = time;
      if (!mediaRef.current.paused) {
        mediaRef.current.play();
      }
    }
  };

  const handleTimeUpdate = () => {
    if (mediaRef.current && !manualUserScroll.current && !manualUserEdition.current) {
      const { currentTime } = mediaRef.current;
      setCurrentMediaTime(currentTime);

      // Trouver le texte associé au moment actuel
      const activeItemIndex = parseTextJson.findIndex((item, index) => {
        const nextItem = parseTextJson[index + 1];
        return currentTime >= parseFloat(item.start) && (!nextItem || currentTime < parseFloat(nextItem.start));
      });

      // Faire défiler vers le texte actif
      if (activeItemIndex !== -1) {
        virtuoso.current.scrollToIndex({
          index: activeItemIndex,
          offset: -100,
          behavior: 'instant',
        });
      }
    }
  };

  const toggleManualUserEdition = (toggle) => {
    manualUserEdition.current = toggle;
    if (toggle && !mediaRef.current.paused) {
      mediaRef.current.pause();
      mediaWasPlayingBeforeEdition.current = true;
    } else if (!toggle) {
      if (mediaWasPlayingBeforeEdition.current) {
        mediaRef.current.play();
      }
      mediaWasPlayingBeforeEdition.current = false;
    }
  };

  return (
    <Fragment>
      {urlVideo && (
        <Card
          sx={{
            maxWidth: '500px',
            maxHeight: '300px',
            mt: 2,
            mb: 0,
            flexShrink: 0,
          }}
        >
          <CardMedia
            sx={{
              '&.MuiCardMedia-root': {
                maxHeight: '300px',
              },
            }}
            component="video"
            controls
            src={urlVideo}
            ref={mediaRef}
            onTimeUpdate={handleTimeUpdate}
          />
        </Card>
      )}
      {urlAudio && (
        <Box sx={{ mr: 2 }}>
          <CardMedia
            sx={{
              maxWidth: '500px',
              mt: 2,
              mb: 2,
              flexShrink: 0,
            }}
            component="audio"
            controls
            onTimeUpdate={handleTimeUpdate}
            src={urlAudio}
            ref={mediaRef}
          />
          <Waveform mediaRef={mediaRef} />
        </Box>
      )}
      <Box
        mt={2}
        whiteSpace="pre-wrap"
        lineHeight="30px"
        sx={{ clear: 'both' }}
        overflow="auto"
        flexGrow="1"
        id="transcription"
      >
        <Virtuoso
          ref={virtuoso}
          data={parseTextJson}
          style={{ height: '100%' }}
          // eslint-disable-next-line react/no-unstable-nested-components
          itemContent={(index, sentence) => (
            <TranscriptionSentence
              currentMediaTime={currentMediaTime}
              handleTranscriptClick={handleTranscriptClick}
              index={index}
              patchDocumentInComplete={patchDocumentInComplete}
              sentence={sentence}
              toggleManualUserEdition={toggleManualUserEdition}
              displayHours={parseFloat(_.last(parseTextJson).start) >= 3600}
            />
          )}
        />
      </Box>
    </Fragment>
  );
};

MediaWithTranscription.propTypes = {
  patchDocumentInComplete: PropTypes.func.isRequired,
  transcriptionJSON: PropTypes.string,
  urlAudio: PropTypes.string,
  urlVideo: PropTypes.string,
};

MediaWithTranscription.defaultProps = {
  transcriptionJSON: [],
  urlAudio: null,
  urlVideo: null,
};

export default MediaWithTranscription;
