import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import { useTheme } from '@mui/styles';
import WaveSurfer from 'wavesurfer.js';

const Waveform = ({ mediaRef }) => {
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);
  const theme = useTheme();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    waveSurferRef.current = WaveSurfer.create({
      container: waveformRef.current,
      cursorColor: '#333',
      barWidth: 2,
      height: 80,
      responsive: true,
      media: mediaRef.current,
    });

    waveSurferRef.current.on('decode', () => {
      setIsReady(true);
    });
    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
      }
    };
  }, [mediaRef]);

  useEffect(() => {
    waveSurferRef.current.setOptions({
      waveColor: theme.palette.mode === 'light' ? '#dddddd' : '#555555',
      progressColor: theme.palette.mode === 'light' ? '#000000' : '#ffffff',
    });
  }, [theme.palette.mode]);

  return (
    <Fragment>
      {!isReady && (
        <Skeleton width="100%" height={80} animation="wave" variant="rectangular" />
      )}
      <Box ref={waveformRef} sx={{ display: isReady ? 'block' : 'none' }} />
    </Fragment>
  );
};

Waveform.propTypes = {
  mediaRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

Waveform.defaultProps = {
  mediaRef: null,
};

export default Waveform;
